import { error_icon } from "assets/svg";
import { Button } from "components/shared/Button";
import Typography from "components/shared/Typography";
import { useNavigate } from "react-router";

interface IBlockQueryError {
  message: string;
  blockId: string;
  reportId: String;
}

export default function BlockQueryError(props: IBlockQueryError) {
  const { message, blockId, reportId } = props;
  const navigate = useNavigate();
  return (
    <div className="my-[60px] flex flex-col justify-center items-center border-[1px] border-grey-100 gap-10 p-6">
      <img src={error_icon} alt="block error" className="h-32 w-32" />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-1">
          <Typography variant="displayxs" weight="semibold" className="text-shade-black text-center">
            Invalid Block
          </Typography>
          <Typography variant="textlg" weight="regular" className="text-grey-500 text-center">
            {message || "There was an unexpected error while retrieving block"}
          </Typography>
        </div>
        <div className="flex justify-center">
          <Button title="Edit Block" fullwidth={false} onClick={() => navigate(`/blocks/${reportId}/edit/${blockId}`)} />
        </div>
      </div>
    </div>
  );
}
