import { BlockCard, Button } from "components";
import Typography from "components/shared/Typography";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import { useFetchBlocks, useGetReportDetails } from "services";
import { useNavigate, useParams } from "react-router";
import { IBlock } from "types";
import { useContext } from "react";
import { LoadingReport } from "./loading-block";
import classNames from "classnames";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { AppContext } from "providers/AppContext";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import PdfRenderer from "components/blocks/PdfRenderer";
import DocxRenderer from "components/blocks/DocxRenderer";

Chart.register(CategoryScale);

export function Report() {
  const { isPdfOpen, dataSourceDownload, dataSourceToDownloadType, setDataSource, closePdf } = useContext(AppContext);

  const param = useParams();
  const navigate = useNavigate();

  const { id } = param;
  const { data, isFetching, error } = useFetchBlocks(id as string);

  const { data: reportData, isFetching: isReportDetailsFetching, error: isReportDetailsError } = useGetReportDetails(id as string);

  const displayBlock = data && data?.data?.length > 0 && !isFetching;

  const blocks = data?.data as Partial<IBlock>[];

  const title = reportData && (reportData?.data?.report?.title as string);

  const goBack = () => {
    closePdf();
    setDataSource("");
    window.history.back();
  };

  return (
    <div className={classNames("flex w-full justify-center transition-all duration-300")}>
      <div
        className={classNames("flex lg:max-w-[900px] md:max-w-[800px] pb-[109px] transition-all duration-300 flex-col", {
          "justify-center w-full border-none": !isPdfOpen,
          "w-[50%] px-[20px] pt-[106px] border-r-[1px] border-grey-100": isPdfOpen,
        })}
      >
        <div className="flex flex-col">
          <div className="flex justify-start">
            <div className="flex gap-3 items-center hover:cursor-pointer mb-8 " onClick={() => goBack()}>
              <FaArrowLeftLong />{" "}
              <Typography variant="body2" className="text-grey-900">
                Go Back
              </Typography>
            </div>
          </div>

          <div
            className={classNames("flex justify-between items-center", {
              "mb-[64px]": displayBlock,
              "mb-[14px]": !displayBlock,
            })}
          >
            {title && (
              <div className="flex items-center gap-8">
                <Button icon={<GoPlus />} title="New Block" onClick={() => navigate(`/blocks/${id}/create`)} />
                <Typography variant="displayxs" weight="bold" className="text-shade-black">
                  {title}
                </Typography>
              </div>
            )}{" "}
            {/* {displayBlock && (
              <div className="flex items-center gap-3 hover:cursor-pointer" onClick={() => setStartDownload(true)}>
                <img src={getIcon("pdf")} alt="file icon" />
                <TfiDownload />
              </div>
            )} */}
          </div>

          {error && (
            <div className="mt-[60px] flex justify-center items-center">
              <Typography variant="displaysm" weight="semibold" className="text-shade-black">
                There was an unexpected error while retrieving blocks
              </Typography>
            </div>
          )}
          {isFetching && (
            <div className="mt-[60px]">
              <LoadingReport />
            </div>
          )}
          {displayBlock && (
            <div>
              <div className="flex flex-col gap-10">
                {blocks.map((block: Partial<IBlock>) => (
                  <BlockCard key={block.id} block={block} reportId={id as string} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames(" transition-all duration-300 ease-out", {
          "w-[50%] block  pt-[106px] px-10 max-w-[800px]": isPdfOpen,
          "hidden w-[0px] transition-all duration-300 ease-out": !isPdfOpen,
        })}
      >
        <div className="sticky top-0 overflow-y-scroll">
          <div className="h-full w-full pb-6">
            {dataSourceDownload && dataSourceToDownloadType === "pdf" && <PdfRenderer />}
            {dataSourceDownload && dataSourceToDownloadType === "docx" && <DocxRenderer />}
          </div>
        </div>
      </div>
    </div>
  );
}
