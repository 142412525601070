import { useNavigate } from "react-router";
import Typography from "../shared/Typography";
import { more_icon } from "assets/svg";
import { useContext, useEffect, useRef, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { IReport } from "types";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { useDeleteReports } from "services";
import { AppContext } from "providers/AppContext";

interface ReportCardProps {
  report: Partial<IReport>;
  selectedReports: number[];
  onToggleSelection: (id: number) => void;
}
export default function ReportCard(props: ReportCardProps) {
  const { report, selectedReports, onToggleSelection } = props;
  const { setDataSource, closePdf } = useContext(AppContext);

  const navigate = useNavigate();

  const { title, id, updated } = report;

  const [reportId, setReportId] = useState(report.id as number);

  const date = new Date(updated as string).toLocaleDateString();
  const time = new Date(updated as string).toLocaleTimeString();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !(dropdownRef?.current as unknown as any)?.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const { deleteReports } = useDeleteReports();

  const customId = "custom-toast-id";

  const deleteReport = () => {
    deleteReports([reportId], {
      onError: (error, variables, context) => {
        toast.error(error?.message || "Error deleteing reports", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });

        toast.success("Report deleted Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
      },
    });
  };

  const openReport = () => {
    closePdf();
    setDataSource("");
    navigate(`${id}`);
  };

  return (
    <div className="flex justify-between items-center cursor-pointer w-full  px-3 border-[1px] border-grey-200 rounded-lg hover:bg-grey-100">
      <div className="flex gap-3 items-center w-full">
        <input
          type="checkbox"
          id="radioColor"
          className="mr-2 h-5 w-5 hover:cursor-pointer"
          checked={!!selectedReports?.find((id: any) => id === (report.id as number))}
          onChange={() => onToggleSelection(id as number)}
        />
        <div className="flex gap-2 items-center h-full w-full py-4" onClick={() => openReport()}>
          <Typography variant="textxl" weight="bold" className="text-shade-black">
            {`${title} - `}
          </Typography>
          <Typography variant="textsm">
            Last Updated {date} {time}
          </Typography>
        </div>
      </div>
      <div className="relative inline-block">
        <div>
          <div className="flex items-center" onClick={toggleDropdown}>
            <img src={more_icon} alt="more" />
          </div>
        </div>
        <div
          ref={dropdownRef}
          className={`z-50 origin-top-right absolute left-3 bottom-6  w-[120px] rounded-md  bg-shade-white border-[1px] border-grey-100  ${isOpen ? "block" : "hidden"} transition-transform duration-600 ease-in-out shadow-sm`}
        >
          <div key={1} className="py-3 px-2 hover:bg-grey-75 hover:cursor-pointer flex items-center gap-2" onClick={() => navigate(`${id}`)}>
            <IoEyeOutline />
            <Typography variant="textsm">View report</Typography>
          </div>
          <div key={2} className="py-3 px-2 hover:bg-grey-75 hover:cursor-pointer flex items-center gap-2" onClick={deleteReport}>
            <MdDeleteOutline />
            <Typography variant="textsm">Delete</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
