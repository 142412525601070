import { search_icon } from "assets/svg";
import { Button } from "components/shared/Button";
import Typography from "components/shared/Typography";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { ISample } from "types";
import { fuzzySearch, getIcon } from "utils";

interface ISampleModal {
  data: Partial<ISample>[];
  setSampleRadioValue: (e: any) => void;
  value: any;
  onClose: (close: boolean) => void;
}

function EditSampleModal(props: ISampleModal) {
  const { data, setSampleRadioValue, onClose, value } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<ISample>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, data));
    }
  }, [searchTerm]);

  const source = searchResults && searchResults?.length > 0 ? searchResults : data;

  const displayDataSource = source?.length > 0;

  const displayNoDataSource = source?.length === 0;

  return (
    <div className="flex bg-[#FFF] flex-col rounded-md w-auto">
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        <Typography variant="textxl" className="font-semibold">
          Choose File Add Source
        </Typography>

        <div onClick={() => onClose(false)} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      {displayNoDataSource && (
        <div className="flex justify-center items-center h-56 px-8">
          <Typography variant="displaysm" className="text-grey-500">
            No Sample Found
          </Typography>{" "}
        </div>
      )}
      {displayDataSource && (
        <>
          <div className="mt-3">
            <div className="relative rounded-md px-6 pt-2">
              <div className="pointer-events-none absolute inset-y-0 left-6 flex items-center pl-3">
                <img className="text-grey-500 sm:text-sm" alt="search" src={search_icon} />
              </div>

              <input
                className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Search samples"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="px-6 pb-8 pt-4 flex gap-4 flex-col overflow-auto w-[470px]  h-[350px]">
            {source?.map((sample: Partial<ISample>, index) => (
              <div className="flex items-center gap-4 hover:cursor-pointer" key={sample.id} onClick={() => setSampleRadioValue(sample)}>
                <input
                  type="radio"
                  id="radioColor"
                  name="fileType"
                  value={value}
                  checked={value?.id === sample.id}
                  onChange={() => {
                    setSampleRadioValue(sample);
                  }}
                  className="w-6 h-6"
                />
                <img src={getIcon("pdf")} alt="pdf" className="h-10 w-10" />
                <Typography variant="body2" className="font-medium">
                  {sample.name}
                </Typography>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="flex justify-end items-center px-6 mb-6 mt-6">
        <Button type="button" title="Continue" onClick={() => onClose(false)} />
      </div>{" "}
    </div>
  );
}

export default EditSampleModal;
