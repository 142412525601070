import { ChartOptions, TickOptions } from "chart.js";
import classNames from "classnames";
import { Bar, Line, Pie } from "react-chartjs-2";
import { transformData } from "utils";

interface ICustomChart {
  chartData: any;
  chartType: string;
  title: string;
}

enum Position {
  bottom = "bottom",
  top = "top",
  left = "left",
  right = "right",
}

function CustomChart(props: ICustomChart) {
  const { chartData, chartType = "pie_chart", title } = props;

  const data = typeof chartData === "string" ? JSON.parse(chartData) : chartData;
  const transformedData = transformData(data);

  const options: ChartOptions<"bar" | "pie" | "line"> = {
    responsive: true,
    plugins: {
      legend: {
        display: chartType === "bar_chart" || chartType === "line_chart" ? false : true,
      },
      title: {
        display: true,
        text: title,
        position: Position.bottom,
      },
    },
    scales:
      chartType === "pie_chart"
        ? undefined
        : {
            x: {
              ticks: {
                callback: function (value: number | string, index: number) {
                  const label = this.getLabelForValue(index);
                  return label.length > 10 ? label.substring(0, 10) + "..." : label;
                },
              },
            },
          },
  };

  return (
    <div
      className={classNames("w-full relative  min-h-[400px] pt-6", {
        "aspect-square": chartType === "pie_chart",
      })}
    >
      <>
        {chartType === "bar_chart" && <Bar data={transformedData} options={options} />}
        {chartType === "pie_chart" && <Pie data={transformedData} options={options} />}
        {chartType === "line_chart" && <Line data={transformedData} options={options as any} />}
      </>
    </div>
  );
}

export default CustomChart;
