import { NoDataComponent } from "components/shared/NoDataComponent";

const Table = (props: any) => {
  const { data } = props;

  const columns = data && data.length > 0 && Object.keys(data[0]);

  return (
    <>
      {data && data.length <= 0 && <NoDataComponent item={"Table Data"} />}
      {data && data.length > 0 && (
        <div style={{ overflowX: "scroll" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr style={{ backgroundColor: "#f3f4f6", color: "#111827" }}>
                {columns.map((header: any) => (
                  <th key={header} style={{ border: "1px solid #6b7280", padding: "8px" }} className="capitalize">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row: any, rowIndex: number) => (
                <tr
                  key={rowIndex}
                  style={{
                    transition: "background-color 0.3s ease",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")}
                >
                  {columns.map((header: any) => (
                    <td key={header} style={{ border: "1px solid #6b7280", padding: "8px" }}>
                      {header === "index" ? Number(row[header]) + 1 : row[header]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Table;
