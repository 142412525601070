import React, { createContext, useState } from "react";
import { IUser } from "types";

export const UserContext = createContext({
  user: {
    id: "",
    token: "",
    email: "",
    is_org: false,
    organization_id: "",
    name: "",
    created: "",
    updated: "",
  } as Partial<IUser>,
  setUserContext: (user: Partial<IUser>) => {},
});

interface IUserProps {
  children: React.ReactNode;
}

function UserProvider(props: IUserProps) {
  const { children } = props;

  const [user, setUser] = useState<Partial<IUser>>({
    id: "",
    token: "",
    email: "",
    is_org: false,
    organization_id: "",
    name: "",
    created: "",
    updated: "",
  });

  const setUserContext = (user: Partial<IUser>) => {
    setUser(user);
  };

  return <UserContext.Provider value={{ user, setUserContext }}>{children}</UserContext.Provider>;
}

export default UserProvider;
