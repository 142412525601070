import { Button } from "../shared/Button";
import Typography from "../shared/Typography";

export function ErrorFallback() {
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div>
        <Typography variant="display3xl">An Error Occured</Typography>
        <div className="mt-6">
          <Button title="Refresh" onClick={() => window.location.reload()} />
        </div>
      </div>
    </div>
  );
}
