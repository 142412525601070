import Typography from "./Typography";

interface IHeaderProps {
  title: string;
}
export default function Header(props: IHeaderProps) {
  const { title } = props;
  return (
    <div className="flex justify-center items-center mb-8 w-[700px]">
      <Typography variant="displaylg" weight="bold" className="text-shade-black font-bold">
        {title}
      </Typography>
    </div>
  );
}
