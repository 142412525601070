import { AddDataSourceModal, Button } from "components";
import Typography from "components/shared/Typography";
import { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { BlockData, ICreateBlock, IDataSource, ISample } from "types";
import { TfiDownload } from "react-icons/tfi";
import { useCreateBlock, useGetReportDataSources, useGetReportSamples } from "services";
import Loading from "components/shared/Loading";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { Modal } from "components/shared/Modal";
import { MdAdd } from "react-icons/md";
import { fuzzySearch, getIcon, validateBlockData } from "utils";
import BlockComponentCard from "components/blocks/BlockComponent";

export function CreateBlock() {
  const param = useParams();

  const { id } = param;

  const { data, error, isFetching } = useGetReportDataSources(id as string);

  const { data: samples, error: samplesError, isFetching: isFetchingSamples } = useGetReportSamples(id as string);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const key = crypto.randomUUID();
  const object: any = {};
  object[key] = {
    query: "",
    result_format: "",
    result_style: "",
    title: "",
    extra_information: "",
    table_row_description: "",
    table_columns: "",
    data_source_id: "",
    // sample_id: "",
    chart_type: "",
    chart_instruction: "",
  } as BlockData;

  const [addedBlocks, setAddedBlocks] = useState<any>({ ...object });

  const addBlocks = () => {
    const key = crypto.randomUUID();
    const object: any = {};
    object[key] = {
      query: "",
      result_format: "",
      result_style: "",
      title: "",
      extra_information: "",
      table_row_description: "",
      table_columns: "",
      data_source_id: "",
      // sample_id: "",
      chart_type: "",
      chart_instruction: "",
    } as BlockData;
    setAddedBlocks({ ...addedBlocks, ...object });
  };

  const removeBlockComponent = (key: string) => {
    delete addedBlocks[key];
    setAddedBlocks({ ...addedBlocks });
  };

  const setBlockProperty = (key: string, propertyName: string, value: string) => {
    addedBlocks[key][propertyName] = value;
    setAddedBlocks({ ...addedBlocks });
  };

  const getBlockProperty = (key: string, propertyName: string) => {
    return addedBlocks[key][propertyName];
  };

  const { createBlock } = useCreateBlock();

  const customId = "custom-toast-id";

  useEffect(() => {
    setIsValidated(validateBlockData(addedBlocks));
  }, [addedBlocks]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const data = { blocks_data: addedBlocks, report_id: id as string };

    createBlock(data, {
      onError: (error, variables, context) => {
        toast.error("Error creating block", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["blocks"] });
        const key = crypto.randomUUID();
        const object: any = {};
        object[key] = {
          query: "",
          result_format: "",
          result_style: "",
          title: "",
          extra_information: "",
          table_row_description: "",
          table_columns: "",
          data_source_id: "",
          sample_id: "",
          chart_type: "",
          chart_instruction: "",
        } as BlockData;
        setAddedBlocks({ ...object });
        toast.success("Block Created Successfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1000,
          theme: "colored",
        });
        setTimeout(() => {
          window.history.back();
        }, 1000);
      },
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Partial<IDataSource>[]>();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setSearchResults(fuzzySearch("name", searchTerm, data?.data.data_sources as Partial<IDataSource>[]));
    }
  }, [searchTerm]);

  const sources = searchResults && searchResults?.length > 0 ? searchResults : data && data?.data.data_sources;

  const displayNoDataSourcesAvailable = sources && sources?.length === 0;

  const displayDataSources = sources && sources?.length > 0 && !isFetching;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className="pb-[69px] max-w-[1400px]">
      <div className="flex justify-start">
        <div className="flex gap-3 items-center hover:cursor-pointer mb-8 " onClick={() => window.history.back()}>
          <FaArrowLeftLong />{" "}
          <Typography variant="body2" className="text-grey-900">
            Go Back
          </Typography>
        </div>
      </div>
      <div className="mb-6">
        <Typography variant="displayxs" className="text-shade-black text-left !font-bold">
          Create New Block
        </Typography>
      </div>

      <div className="flex gap-6">
        <div className="w-[682px]">
          <div className="flex flex-col gap-6">
            {Object.keys(addedBlocks).map((blockKey, key) => (
              <div key={key}>
                <BlockComponentCard
                  allDataSources={data?.data.data_sources as Partial<IDataSource>[]}
                  allDataSamples={samples?.data?.samples as Partial<ISample>[]}
                  setBlockProperty={setBlockProperty}
                  getBlockProperty={getBlockProperty}
                  blockKey={blockKey}
                  removeBlockComponent={removeBlockComponent}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-between gap-4 mt-4">
            <div className="flex justify-end">
              <Button onClick={addBlocks} type="button" title="Add" icon={<MdAdd />} />
            </div>
            <Button isSubmitting={isSubmitting} type="submit" title="Save" fullwidth disabled={isSubmitting || isValidated} onClick={handleSubmit} />
          </div>
        </div>
        <div className="w-full max-w-[482px] py-10  border-[1.2px] border-grey-200 rounded-md max-h-[600px] overflow-y-auto">
          <div className="flex justify-between items-center px-5">
            <Typography variant="textxl" className="!font-semibold text-shade-black text-left">
              Included Data Sources
            </Typography>

            <div className="" onClick={() => setModalOpen(true)}>
              <Button icon={<TfiDownload />} title="Upload Another file" type="submit" isSubmitting={isSubmitting} />
            </div>
          </div>
          {modalOpen && (
            <Modal
              onClose={() => setModalOpen(false)}
              open={modalOpen}
              variant="full"
              children={<AddDataSourceModal onClose={() => setModalOpen(false)} report_id={id} />}
            />
          )}
          <hr className="text-grey-200 mt-6" />

          {/* <div className="px-5">
            <div className="relative rounded-md py-2 mt-3 ">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <img className="text-grey-500 sm:text-sm" alt="search" src={search_icon} />
              </div>

              <input
                className="h-12 px-3 pl-10 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-primary-200 focus:ring-primary-200 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Search data source"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div> */}

          <div className="px-5">
            {isFetching && (
              <div className="mt-4">
                {" "}
                <Loading numberOfLoaders={4} height={10} className="bg-grey-300" />
              </div>
            )}
            {displayNoDataSourcesAvailable && (
              <div className="flex justify-center items-center">
                <Typography variant="displayxs" weight="semibold">
                  No data source available
                </Typography>{" "}
              </div>
            )}
          </div>
          <div className="mt-4 flex gap-4 flex-col px-5">
            {displayDataSources &&
              sources?.map((dataSource: Partial<IDataSource>) => (
                <div key={dataSource.id} className="">
                  <div className="flex gap-4 items-center  hover:cursor-pointer hover:bg-grey-100 px-[2px] py-2">
                    <img src={getIcon(dataSource.type as string)} alt="pdf" className="h-10 w-10" />
                    <Typography variant="body2" className="!font-semibold">
                      {dataSource.name}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
