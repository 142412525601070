import { SideBar } from "components";
import { AppContext } from "providers/AppContext";
import { useContext } from "react";
import { Outlet } from "react-router";

export default function MainLayout() {
  const { isPdfOpen } = useContext(AppContext);

  const layoutStyle = {
    paddingLeft: isPdfOpen ? "0px" : "120px",
    paddingRight: isPdfOpen ? "0px" : "120px",
    paddingTop: isPdfOpen ? "0px" : "106px",
  };

  return (
    <div className="flex min-h-screen w-[100%] bg-[#151529]">
      <div className="max-w-[288px] w-[287px] px-5 max-h-screen z-10 sticky top-0">
        <div className="">
          <SideBar />
        </div>
      </div>
      <div
        className="my-4 mr-4 border-[1.2px] border-grey-200 w-full rounded-[20px] bg-[#FFF] transition-all duration-300 ease-in-out"
        style={layoutStyle}
      >
        <div className="flex justify-center items-center ">
          <Outlet />{" "}
        </div>
      </div>
    </div>
  );
}
