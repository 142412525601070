import ReactPaginate, { PaginationProps as ReactPaginateProps } from "rc-pagination";
import styled from "styled-components";
import { ReactNode } from "react";
import Typography from "./Typography";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const StyledPagination = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;

  li a {
    height: 40px;
    min-width: 40px;
    border-radius: 12px;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.14px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #9ca3af;
  }

  li.rc-pagination-item-active a {
    border: 2px solid #6141ac;
    color: #212121;
    background-color: #f9fafb;
  }

  li.rc-pagination-jump-next a,
  li.rc-pagination-jump-prev a {
    font-size: 22px;
    letter-spacing: -0.22px;
    line-height: 31px;
    background: transparent;
    border: none;
  }

  li.rc-pagination-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  li.rc-pagination-total-text {
    margin-right: 17px;
  }

  li.rc-pagination-next a,
  li.rc-pagination-prev a {
    width: 120px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 2px solid #d1d5db;
    color: #374151;
  }

  li.rc-pagination-prev a {
    margin-right: 8px;
  }

  li.rc-pagination-next a {
    margin-left: 8px;
  }
`;

interface PaginationProps extends Omit<ReactPaginateProps, "pageSize"> {
  onChangePage: (page: number) => void;
  pageSize: number;
  currentPage: number;
  total: number;
}
export function Pagination(props: PaginationProps) {
  const { total, onChangePage, currentPage, pageSize, ...others } = props;

  const getItemRender = (current: number, type: "page" | "prev" | "next" | "jump-prev" | "jump-next") => {
    let item: ReactNode;
    switch (type) {
      case "page":
        item = current;
        break;
      case "jump-next":
      case "jump-prev":
        item = "...";
        break;
      case "prev":
        item = (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <FaChevronLeft /> <Typography variant="textmd">Previous</Typography>
          </div>
        );
        break;
      case "next":
        item = (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography variant="textmd">Next</Typography>
            <FaChevronRight />
          </div>
        );
        break;
      default:
        item = "";
    }
    return <a>{item}</a>;
  };

  return (
    <div className={"flex justify-end items-center"}>
      <div className={"flex items-center gap-[21px]"}>
        <StyledPagination
          {...others}
          onChange={onChangePage}
          pageSize={10}
          itemRender={getItemRender}
          current={currentPage}
          total={total || 0}
          showTotal={(total, range) => {
            return (
              <Typography variant={"p2"} color="text.secondary">
                {range[0]}-{range[1]} of {total} Results
              </Typography>
            );
          }}
        />
      </div>
    </div>
  );
}
