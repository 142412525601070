import { AppContext } from "providers/AppContext";
import { useContext, useEffect, useState } from "react";
import DocViewer from "@cyntler/react-doc-viewer";
import { useGetDownloadPdf } from "services";
import Typography from "../shared/Typography";
import Loading from "../shared/Loading";
import { IoMdClose } from "react-icons/io";
import "@cyntler/react-doc-viewer/dist/index.css";

export default function DocxRenderer() {
  const {
    startDataSourceDownload,
    dataSourceDownload,
    togglePdfModal,
    dataSourceToDownload,
    setDataSource,
    renderedPage,
    closePdf,
    isPdfOpen,
    dataSourceToDownloadName,
  } = useContext(AppContext);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [pdfURL, setPdfURL] = useState<string | null>(null);

  useEffect(() => {
    setPageNumber(renderedPage);
  }, [renderedPage]);

  const { data, isFetching, error } = useGetDownloadPdf(dataSourceToDownload as string, dataSourceDownload);

  useEffect(() => {
    if (data) {
      setPdfURL(data.data.presigned_url);
    }
  }, [data]);

  const docs = [{ uri: pdfURL as string, fileType: "docx" }];

  const RenderError = () => {
    return (
      <div className="flex justify-center items-center h-[calc(100vh-40rem)]">
        <Typography variant="textsm" className="text-sm">
          An Error Occured fetching file
        </Typography>
      </div>
    );
  };

  const RenderLoading = () => {
    return (
      <div className="flex justify-center pb-8">
        <div className="h-full w-[408px] mt-20">
          <Loading numberOfLoaders={10} className="bg-grey-200 !h-4" />
        </div>
      </div>
    );
  };

  const onClose = () => {
    togglePdfModal();
    setDataSource("");
  };

  return (
    <div>
      {error && <RenderError />}
      {isFetching && <RenderLoading />}
      {!isFetching && data && (
        <div className="pb-8 h-[calc(100vh-8rem)] w-full flex flex-col">
          <div className="flex flex-col gap-2.5">
            <div className="flex justify-end items-center">
              <div
                aria-label="close"
                className="hover:bg-grey-100 hover:cursor-pointer flex justify-center items-center  rounded-full  h-8 w-8"
                onClick={() => onClose()}
              >
                <IoMdClose className=" h-6 w-6" />
              </div>
            </div>
            <div className="flex justify-center items-center py-2.5 bg-grey-200">
              <Typography variant="textxl" className="text-shade-black text-center">
                {dataSourceToDownloadName}
              </Typography>
            </div>
          </div>
          {pdfURL && !isFetching && (
            <DocViewer
              className="border-[1px] border-grey-100"
              documents={docs}
              initialActiveDocument={docs[0]}
              config={{
                noRenderer: {
                  overrideComponent: RenderError,
                },
                loadingRenderer: {
                  overrideComponent: RenderLoading,
                  showLoadingTimeout: false,
                },
                header: { disableHeader: true, disableFileName: false },
              }}
            />
          )}{" "}
        </div>
      )}
    </div>
  );
}
