import { MdAdd } from "react-icons/md";
import { Button } from "../shared/Button";
import Typography from "../shared/Typography";
import { useContext, useState } from "react";
import { CreateReport } from "pages/reports/create-report";
import { Modal } from "../shared/Modal";
import { UserContext } from "providers/UserContext";

export default function EmptyReportsPage() {
  const { user } = useContext(UserContext);
  const { name } = user;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <div className="flex flex-col gap-10 items-center max-w-screen">
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} open={modalOpen} variant="full" children={<CreateReport onClose={() => setModalOpen(false)} />} />
      )}
      <div className="flex flex-col gap-3 items-center">
        <Typography variant="display2xl" weight="bold" className="gradient-text text-center">
          Hello {name}
        </Typography>
        <Typography variant="dislayxs" weight="regular" className="text-grey-500">
          What would you like to analyze today?
        </Typography>
      </div>
      <Button icon={<MdAdd />} title={"Create a Report"} onClick={() => setModalOpen(true)} />
    </div>
  );
}
