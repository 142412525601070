import Typography from "../shared/Typography";
import { useState } from "react";
import { getIcon } from "utils";

interface IDataSourceComponent {
  name: string;
  id: string;
  updated: string;
  type: string;
  sample?: true;
}
export default function DataSourceComponent(props: IDataSourceComponent) {
  const { name, id, updated, type, sample } = props;

  const date = new Date(updated).toLocaleDateString();
  const time = new Date(updated).toLocaleTimeString();

  const icon = getIcon(type as string);

  return (
    <div className="flex justify-between items-center cursor-pointer w-full py-4 px-3 border-[1px] border-grey-200 rounded-lg hover:bg-grey-100">
      <div className="flex gap-3 items-center w-full">
        {!sample && <img src={icon} alt="pdf" className="w-[60px] h-[60px] rounded-lg" />}
        <div className="flex gap-2 justify-center flex-col items-start">
          {!sample && (
            <Typography variant="body2" className="text-shade-black font-semibold">
              {`${name}.${type}`}
            </Typography>
          )}

          {sample && (
            <Typography variant="body2" className="text-shade-black font-semibold">
              {`${name}`}
            </Typography>
          )}
          <Typography variant="textsm" className="text-grey-500 font-regular">
            Last Updated {date} {time}
          </Typography>
        </div>
      </div>
    </div>
  );
}
