import { briefcase_icon, delete_icon, green_checkmark_icon, red_trash_icon } from "assets/svg";
import React, { ChangeEvent, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Typography from "./Typography";
import { toast } from "react-toastify";
import classNames from "classnames";
import { LiaUploadSolid } from "react-icons/lia";
import { FileTypes } from "types";

export const DOCUMENT_TYPES = [
  "application/pdf",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const ACCEPTED_FILE_TYPE = {
  pdf: { "application/pdf": [".pdf"] },
  csv: { "text/csv": [".csv"] },
  docx: { "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"] },
  xlsx: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] },
};

export enum IDropzoneType {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}
interface IDropzone {
  setFile: Function;
  dropZoneType: IDropzoneType;
  fileType?: FileTypes;
}

export function DropZone(props: IDropzone) {
  const { setFile, dropZoneType, fileType } = props;

  const accept = fileType && ACCEPTED_FILE_TYPE[fileType];

  const [preview, setPreview] = useState<File | null>();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const customId = "custom-toast-id";

    const file = acceptedFiles[0];

    if (file && file.size > 5000000) {
      toast.error("File size should be less than 50MB", {
        position: "top-center",
        toastId: customId,
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }

    if (file && !DOCUMENT_TYPES.includes(file.type)) {
      toast.error("File type not supported", {
        position: "top-center",
        toastId: customId,
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }

    if (file) {
      setPreview(file);
      setFile(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept,
  });

  return (
    <div
      {...getRootProps()}
      className={classNames("w-full flex flex-col gap-2 bg-grey-50 border-[1px] border-dashed border-grey-200 rounded-md hover:cursor-pointer", {
        "h-auto": dropZoneType === IDropzoneType.SMALL,
        "h-40": dropZoneType === IDropzoneType.LARGE,
      })}
    >
      <input {...getInputProps()} />
      {isDragActive && !preview && (
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-1.5">
            <Typography variant="textsm" className="font-regular text-shade-black">
              Drag and drop file(s) or
            </Typography>
            <div onClick={open}>
              <Typography variant="textsm" className=" font-regular text-primary-700">
                {" "}
                browse
              </Typography>
            </div>
          </div>
          <LiaUploadSolid />
        </div>
      )}{" "}
      {!isDragActive && !preview && (
        <div className="p-3 flex justify-between items-center w-full">
          <div className="flex items-center gap-1.5">
            <Typography variant="textsm" className="font-regular text-shade-black">
              Drag and drop file(s) or
            </Typography>
            <div onClick={open}>
              <Typography variant="textsm" className=" font-regular text-primary-700">
                {" "}
                browse
              </Typography>
            </div>
          </div>
          <LiaUploadSolid />
        </div>
      )}
      {preview && (
        <div className=" h-full w-full flex items-center justify-between p-3">
          <div className="flex items-center gap-3">
            <img src={green_checkmark_icon} alt="chcekmark" />
            <Typography variant="textsm" className="font-regular text-shade-black">
              {preview?.name}
            </Typography>
          </div>
          <img
            src={delete_icon}
            alt="close"
            onClick={() => {
              setPreview(null);
              setFile(null);
            }}
          />
        </div>
      )}
    </div>
  );
}

// TODO
// MAX-SIZE
// ADD MULTIPLE FILE TYPES
// SET ACCEPT FROM SELECTED FILE TYPE
