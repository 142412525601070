import { useContext, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import Loading from "../shared/Loading";
import { toast } from "react-toastify";
import { useResizeDetector } from "react-resize-detector";
import Typography from "../shared/Typography";
import { IoMdClose } from "react-icons/io";
import { AppContext } from "providers/AppContext";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useGetDownloadPdf } from "services";
import classNames from "classnames";

export default function PdfRenderer() {
  const {
    startDataSourceDownload,
    dataSourceDownload,
    togglePdfModal,
    dataSourceToDownload,
    setDataSource,
    renderedPage,
    closePdf,
    isPdfOpen,
    dataSourceToDownloadName,
  } = useContext(AppContext);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [pdfURL, setPdfURL] = useState<string | null>(null);

  useEffect(() => {
    setPageNumber(renderedPage);
  }, [renderedPage]);

  const { width, ref } = useResizeDetector();

  const { data, isFetching, error } = useGetDownloadPdf(dataSourceToDownload as string, dataSourceDownload);

  console.log(error, "error");

  useEffect(() => {
    if (data) {
      setPdfURL(data.data.presigned_url);
    }
  }, [data]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const onClose = () => {
    togglePdfModal();
    setDataSource("");
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const customId = "custom-toast-id";

  return (
    <div className="flex-1 w-full">
      {error && (
        <div className="flex justify-center items-center h-[calc(100vh-40rem)]">
          <Typography variant="textlg" className="text-sm text-center">
            {error?.message || "An Error Occured fetching PDF"}
          </Typography>
        </div>
      )}
      {isFetching && (
        <div className="flex justify-center pb-8 h-[calc(100vh-20rem)]">
          <div className="h-full w-[408px] mt-20">
            <Loading numberOfLoaders={10} className="bg-grey-200 !h-4" />
          </div>
        </div>
      )}
      {pdfURL && !isFetching && (
        <>
          <div className="flex flex-col">
            <div className="flex flex-col gap-2.5">
              <div className="flex justify-between items-center">
                <div className="flex justify-between items-center gap-2">
                  <div onClick={prevPage} className="hover:bg-grey-100 hover:cursor-pointer flex justify-center items-center  rounded-md p-2">
                    <BsChevronLeft aria-label="previous page" />
                  </div>
                  <div onClick={nextPage} className="hover:bg-grey-100 hover:cursor-pointer flex justify-center items-center  rounded-md p-2">
                    <BsChevronRight aria-label="next page" />
                  </div>
                </div>

                <div
                  aria-label="close"
                  className="hover:bg-grey-100 hover:cursor-pointer flex justify-center items-center  rounded-full  h-8 w-8"
                  onClick={onClose}
                >
                  <IoMdClose className=" h-6 w-6" />
                </div>
              </div>
              <div className="flex justify-center items-center py-2.5 bg-grey-200">
                <Typography variant="textxl" className="text-shade-black text-center">
                  {dataSourceToDownloadName}
                </Typography>
              </div>
            </div>
            {pdfURL && (
              <div ref={ref} className='overflow-hidden'>
                <Document
                  loading={
                    <div className="flex justify-center pb-8 h-[calc(100vh-20rem)]">
                      <div className="h-full w-[408px] mt-20">
                        <Loading numberOfLoaders={10} className="bg-grey-200 !h-4" />
                      </div>
                    </div>
                  }
                  file={pdfURL}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={() => {
                    toast.error("Error Rendering Selected Pdf", {
                      position: "top-center",
                      toastId: customId,
                      autoClose: 3000,
                      theme: "colored",
                    });
                  }}
                  noData={
                    <div className="flex justify-center pb-8 items-center h-[calc(100vh-20rem)]">
                      <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
                        No PDF Available
                      </Typography>
                    </div>
                  }
                  error={
                    <div className="flex justify-center pb-8 items-center h-[calc(100vh-20rem)]">
                      <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
                        An Error Occured While Rendering The PDF
                      </Typography>
                    </div>
                  }
                  className={classNames({
                    "border-[1px] border-grey-100 ": pdfURL,
                  })}
                >
                  <Page width={width ? width : 1} pageNumber={pageNumber} />
                </Document>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
