import { MdAdd, MdDashboard, MdOutlineContactPage } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { Context_logo_light } from "assets/images";
import Typography from "./Typography";
import { Modal } from "./Modal";
import { CreateReport } from "pages/reports/create-report";
import { useContext, useState } from "react";
import classNames from "classnames";
import { logout_icon, reports_sidebar_icon } from "assets/svg";
import { SetDetails } from "utils";
import { UserContext } from "providers/UserContext";
import { MdLogout } from "react-icons/md";

enum ISidebarComponents {
  REPORTS = "reports",
  DATASOURCES = "data-sources",
  SAMPLES = "samples",
}

export function SideBar() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { setUserContext, user } = useContext(UserContext);

  const { email, name } = user;

  const [currentHover, setCurrentHover] = useState<ISidebarComponents>();

  const [logoutHover, setLogoutHover] = useState<boolean>(false);

  const { pathname } = useLocation();

  const activeTab = pathname?.split("/")[1] as ISidebarComponents;

  const logout = () => {
    setUserContext({
      id: "",
      token: "",
      email: "",
      is_org: false,
      organization_id: "",
      name: "",
      created: "",
      updated: "",
    });
    SetDetails("token", "");
    navigate("/auth");
  };

  const firstLetter = name?.split("")[0];

  return (
    <div className="sticky h-screen w-full flex flex-col items-stretch pt-[43.11px] pb-4">
      <div className="flex-1">
        {modalOpen && (
          <Modal
            onClose={() => setModalOpen(false)}
            open={modalOpen}
            variant="full"
            children={<CreateReport onClose={() => setModalOpen(false)} />}
          />
        )}
        <div className="mb-[47.32px]">
          <img src={Context_logo_light} alt="context data" />
        </div>
        <div className="flex items-center gap-1 cursor-pointer p-3 w-full bg-[#ededef0f] mb-5 rounded " onClick={() => setModalOpen(true)}>
          <MdAdd className="text-shade-white regular w-[22px] h-[22px]" />{" "}
          <Typography variant="textsm" className="text-shade-white">
            New Report
          </Typography>
        </div>

        <div
          className={classNames("text-left cursor-pointer  p-3 w-full flex justify-center gap-2", {
            "text-shade-white": activeTab === ISidebarComponents.REPORTS,
            "hover:text-shade-white text-grey-500": activeTab !== ISidebarComponents.REPORTS,
          })}
          onClick={() => navigate(ISidebarComponents.REPORTS)}
          onMouseEnter={() => setCurrentHover(ISidebarComponents.REPORTS)}
          onMouseLeave={() => setCurrentHover(activeTab)}
        >
          <img
            src={reports_sidebar_icon}
            alt="reports"
            className={classNames("w-5 h-5", {
              "!bg-shade-white": activeTab === ISidebarComponents.REPORTS || currentHover === ISidebarComponents.REPORTS,
              "hover:bg-shade-white bg-grey-500": activeTab !== ISidebarComponents.REPORTS || currentHover === ISidebarComponents.REPORTS,
            })}
          />
          <Typography variant="textsm" className={classNames("text-left cursor-pointer w-full")}>
            Reports
          </Typography>
        </div>
        <div
          className={classNames("text-left cursor-pointer p-2 w-full flex  gap-1 items-start", {
            "text-shade-white": activeTab === ISidebarComponents.DATASOURCES,
            "hover:text-shade-white text-grey-500": activeTab !== ISidebarComponents.DATASOURCES,
          })}
          onClick={() => navigate(ISidebarComponents.DATASOURCES)}
          onMouseEnter={() => setCurrentHover(ISidebarComponents.DATASOURCES)}
          onMouseLeave={() => setCurrentHover(activeTab)}
        >
          {" "}
          <div>
            <MdDashboard
              className={classNames("w-[28px] h-[28px]", {
                "!text-shade-white": activeTab === ISidebarComponents.DATASOURCES || currentHover === ISidebarComponents.DATASOURCES,
                "hover:text-shade-white text-grey-500":
                  activeTab !== ISidebarComponents.DATASOURCES || currentHover === ISidebarComponents.DATASOURCES,
              })}
            />
          </div>
          <div className="flex items-center h-full pt-1">
            <Typography variant="textsm" className={classNames("text-left cursor-pointer")}>
              Data sources
            </Typography>
          </div>
        </div>
        {/* <div
          className={classNames("text-left cursor-pointer p-2 w-full flex  gap-1 items-start", {
            "text-shade-white": activeTab === ISidebarComponents.SAMPLES,
            "hover:text-shade-white text-grey-500": activeTab !== ISidebarComponents.SAMPLES,
          })}
          onClick={() => navigate(ISidebarComponents.SAMPLES)}
          onMouseEnter={() => setCurrentHover(ISidebarComponents.SAMPLES)}
          onMouseLeave={() => setCurrentHover(activeTab)}
        >
          {" "}
          <div>
            <MdOutlineContactPage
              className={classNames("w-[28px] h-[28px]", {
                "!text-shade-white": activeTab === ISidebarComponents.SAMPLES || currentHover === ISidebarComponents.SAMPLES,
                "hover:text-shade-white text-grey-500": activeTab !== ISidebarComponents.SAMPLES || currentHover === ISidebarComponents.SAMPLES,
              })}
            />
          </div>
          <div className="flex items-center h-full pt-1">
            <Typography variant="textsm" className={classNames("text-left cursor-pointer")}>
              Samples
            </Typography>
          </div>
        </div> */}
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className={classNames("text-left p-3 w-full ")}>
          <div
            onMouseEnter={() => setLogoutHover(true)}
            onMouseLeave={() => setLogoutHover(false)}
            className="flex w-[90px] gap-2 cursor-pointer items-center"
            onClick={() => logout()}
          >
            <MdLogout
              className={classNames("w-[28px] h-[28px]", {
                "!text-shade-white": logoutHover,
                " text-grey-500": !logoutHover,
              })}
            />
            <Typography
              variant="textsm"
              className={classNames(" text-grey-500 text-left cursor-pointer w-full", {
                "text-shade-white": logoutHover,
              })}
            >
              Log Out
            </Typography>
          </div>
        </div>
        <div className="flex gap-2 items-center py-2 px-3 w-full">
          <div className="flex items-center justify-center w-10 h-10 bg-shade-white rounded-full">{firstLetter}</div>
          <div className="flex flex-col gap-[2px] items-start">
            <Typography variant="textsm" className={classNames("text-left text-shade-white cursor-pointer w-full")}>
              {name}
            </Typography>
            <Typography variant="textxs" className={classNames("text-left text-shade-white cursor-pointer w-full")}>
              {email}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
