import { send_icon } from "assets/svg";
import classNames from "classnames";
import Loading from "components/shared/Loading";
import Typography from "components/shared/Typography";
import { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useChatQueries } from "services";
import MarkdownPreview from "@uiw/react-markdown-preview";

interface IChadModalInterface {
  previous_response: string;
  onClose: () => void;
  report_id: string;
  block_id: string;
  setChatMessages: (value: any) => void;
  chatMessages: IChatMessages[];
  open: boolean;
}

export interface IChatMessages {
  query: string;
  response: any;
}

export function Chat(props: IChadModalInterface) {
  const { previous_response, onClose, report_id, block_id, setChatMessages, chatMessages, open } = props;

  useEffect(() => {
    function preventBodyScroll() {
      document.body.style.overflow = "hidden";
    }

    function allowBodyScroll() {
      document.body.style.overflow = "auto";
    }

    if (open) {
      preventBodyScroll();
    } else {
      allowBodyScroll();
    }

    return () => {
      allowBodyScroll();
    };
  }, [open]);

  const [query, setQuery] = useState("");
  const [session_id, setSession_id] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isQueryEmpty, setIsQueryError] = useState(false);
  const [question, setQuestion] = useState("");

  const { chatQuery } = useChatQueries();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsQueryError(false);
    setQuery(e.target.value);
  };

  const myDivRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollTop = myDivRef.current.scrollHeight;
    }
  };

  const handleSubmit = async () => {
    if (query === "") {
      setIsQueryError(true);
      return;
    }

    setQuestion(query);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
    setIsLoading(true);
    const data = { session_id, query, report_id, block_id };

    const customId = "custom-toast-id";

    setQuery("");
    chatQuery(data, {
      onError: (error, variables, context) => {
        setIsError(true);
        setIsLoading(false);
      },
      onSuccess: (data, variables, context) => {
        const { session_id, content } = data.data;
        setSession_id(session_id);
        setIsLoading(false);
        setIsError(false);
        setChatMessages((prev: IChatMessages[]) => [...prev, { query, response: content }]);
      },
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="h-screen w-full flex flex-col justify-between">
      <div className="border-b-[1px] border-grey-100 px-6 pt-6 pb-4">
        <div className="flex justify-end items-center">
          <div
            aria-label="close"
            className="hover:bg-grey-100 hover:cursor-pointer flex justify-center items-center  rounded-full  h-8 w-8"
            onClick={onClose}
          >
            <IoMdClose className=" h-6 w-6" />
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full overflow-y-scroll" ref={myDivRef}>
        <div className="w-full">
          {isError && (
            <div className="flex justify-center items-center p-6">
              <div className=" h-full w-[100%] mt-10">
                <Typography variant="displayxs" className="text-center text-grey-500">
                  Error fetching result
                </Typography>{" "}
              </div>
            </div>
          )}
          <div className="gap-6 flex flex-col h-full overflow-y-scroll p-3">
            {chatMessages.length > 0 &&
              chatMessages.map((message: IChatMessages) => (
                <div className="flex flex-col gap-3" key={message.query + Math.random()}>
                  <div className="max-w-[60%]">
                    <div className="px-4 py-3 w-fit bg-primary-50 rounded-lg">
                      <Typography variant="textsm" className="text-shade-black">
                        {message.query}
                      </Typography>{" "}
                    </div>
                  </div>
                  <div className="h-full w-full flex justify-end mt-1 ">
                    <div className="bg-primary-100 rounded-lg max-w-[80%] overflow-auto max-h-[600px]   px-4 py-3">
                      <MarkdownPreview
                        source={message.response}
                        wrapperElement={{
                          "data-color-mode": "light",
                        }}
                        style={{
                          backgroundColor: "#e3e3fc",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}

            {isLoading && (
              <div className="flex flex-col gap-3">
                <div className="max-w-[60%]">
                  <div className="px-4 py-3 w-fit bg-primary-50 rounded-lg">
                    <Typography variant="textsm" className="text-shade-black">
                      {question}
                    </Typography>{" "}
                  </div>
                </div>
                <div className="flex justify-end items-start">
                  <div className=" h-full w-[80%]">
                    <Loading numberOfLoaders={1} className="bg-primary-100 !h-40" />
                  </div>
                </div>{" "}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative mt-2 rounded-md p-6 " id="loadingChat">
        <input
          className={classNames(
            "bg-grey-75 py-4 px-3 pl-3  border shadow-sm  placeholder-grey-400 focus:outline-none   block w-full rounded-md sm:text-sm focus:ring-1",
            {
              "focus:border-primary-200 border-grey-300 focus:ring-primary-200": !isQueryEmpty,
              "focus:border-error-500 border-error-300 focus:ring-error-500": isQueryEmpty,
            }
          )}
          placeholder="Type here..."
          value={query}
          onChange={(e) => handleChange(e)}
          onKeyDown={handleKeyPress}
          required
        />
        <div className="absolute inset-y-0 right-8 flex items-center hover:cursor-pointer">
          <div className="h-10 w-10 rounded-full bg-shade-white flex items-center justify-center" onClick={() => handleSubmit()}>
            <img className="w-6 h-6 text-gray-500 sm:text-sm" alt="send message" src={send_icon} />
          </div>
        </div>
      </div>
    </div>
  );
}
