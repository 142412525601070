import classNames from "classnames";
import { useEffect } from 'react';

interface IModalProps {
  onClose?: () => void;
  open: boolean;
  children: React.ReactNode;
  variant: "mutation" | "chat" | "full";
}

function MutationModal(props: IModalProps) {
  const { onClose, open, children } = props;
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white border border-gray-300 rounded-lg p-6">
      <div className="text-white rounded-lg p-4 mb-4">{children}</div>
      <button className="bg-red-500 text-white rounded-lg p-2" onClick={onClose}>
        Close
      </button>
    </div>
  );
}

function ChatModal(props: IModalProps) {
  const { onClose, open, children } = props;
  return (
    <div className="absolute right-0 transform w-[50%] h-screen bg-shade-white border border-grey-300">
      <div className="text-white rounded-lg">{children}</div>
      {/* <button className="bg-red-500 text-white rounded-lg p-2" onClick={onClose}>
        Close
      </button> */}
    </div>
  );
}

function FullScreenModal(props: IModalProps) {
  const { open, children } = props;
  return (
    <div className="h-full flex justify-center items-center">
      <div className="">{children}</div>
    </div>
  );
}

export function Modal(props: IModalProps) {
  const { open, variant } = props;

  useEffect(() => {
    function preventBodyScroll() {
      document.body.style.overflow = "hidden";
    }

    function allowBodyScroll() {
      document.body.style.overflow = "auto";
    }

    if (open) {
      preventBodyScroll();
    } else {
      allowBodyScroll();
    }

    return () => {
      allowBodyScroll();
    };
  }, [open]);

  

  return (
    <>

      <div className="relative">
        <div
          className={classNames(
            "fixed inset-0 top-0 right-0 z-50 w-full h-full bg-shade-black bg-opacity-50 transition-all duration-300 ease-in-out",
            {
              "right-0": open,
              "right-full": !open,
            }
          )}
        >
          {variant === "mutation" && <MutationModal {...props} />}
          {variant === "chat" && <ChatModal {...props} />}
          {variant === "full" && <FullScreenModal {...props} />}
        </div>
      </div>
    </>
  );
}
