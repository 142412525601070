import { amazon_s3, file_icon, folder_icon, password_lock_icon, view_password_icon } from "assets/svg";
import classNames from "classnames";
import { Button } from "components/shared/Button";
import Typography from "components/shared/Typography";
import { queryClient } from "index";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDataSourceFromS3, useFetchDataSourceFromS3 } from "services";
import { FileTypes, IAmazonS3Payload, ISeparatorEnumValue, ModelInView, S3ModelInView } from "types";
import { SlArrowRight } from "react-icons/sl";
import { IoMdClose } from "react-icons/io";

interface IAmazonS3FormProps {
  setModalInView: (value: ModelInView) => void;
  selectedFileType: string;
  onClose: () => void;
  report_id?: string;
}

interface S3Objects {
  name: string;
  location: string;
  size: string;
  content_type: "file" | "folder";
  last_modified: string;
}

export const AmazonS3 = (props: IAmazonS3FormProps) => {
  const { setModalInView, selectedFileType, onClose, report_id } = props;

  const [separator, setSeparator] = useState<ISeparatorEnumValue>();

  const [accessKey, setAccessKey] = useState<string>("");
  const [accessSecret, setAccessSecret] = useState<string>("");
  const [bucketName, setBucketName] = useState<string>("");
  const [view, setView] = useState<S3ModelInView>(S3ModelInView.ACCESS);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = useState("");
  const [previousFolder, setPreviousFolder] = useState("");
  const [backNav, setBackNav] = useState("");
  const [baseFolder, setBaseFolder] = useState<boolean>(false);
  const [goingBack, setGoingBack] = useState<boolean>(false);

  const [radioValue, setRadioValue] = useState<any>(null);

  const accessButtonValidation = !accessKey || isSubmitting || !accessSecret || !bucketName;
  const disableButton =
    view === S3ModelInView.ACCESS ? accessButtonValidation : !radioValue || (radioValue && radioValue.content_type !== "file") || isSubmitting;

  const [showSecret, setShowSecret] = useState(false);

  const [s3Objects, setS3Objects] = useState([]);

  const toggleSecretVisibility = () => {
    setShowSecret(!showSecret);
  };

  const { fetchFromS3, isPending } = useFetchDataSourceFromS3();

  const { uploadFromS3 } = useDataSourceFromS3();

  const customId = "custom-toast-id";

  const getS3Access = async () => {
    setIsSubmitting(true);
    const data: IAmazonS3Payload = {
      access_key: accessKey,
      access_secret: accessSecret,
      bucket_name: bucketName,
      folder: "",
    };

    fetchFromS3(data, {
      onError: (error, variables, context) => {
        const errorMessage = (error?.message as string) || "Error fetching data source";

        toast.error(errorMessage, {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        const filterFolders = data?.data?.objects[0]?.location.split("/");
        const folderName = filterFolders[filterFolders.length - 2];

        setS3Objects(data?.data?.objects);
        setView(S3ModelInView.FOLDER);
        setIsSubmitting(false);
        setCurrentFolder(folderName);
        setBaseFolder(true);
        setBackNav("");
      },
    });
  };

  const fetchFolderObjects = async (value: S3Objects) => {
    setIsSubmitting(true);

    const data: IAmazonS3Payload = {
      access_key: accessKey,
      access_secret: accessSecret,
      bucket_name: bucketName,
      folder: (value && value.location) || "",
    };
    fetchFromS3(data, {
      onError: (error, variables, context) => {
        toast.error("Error fetching data source", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        const filterFolders = data?.data?.objects[0]?.location.split("/");

        const folderName = filterFolders[filterFolders.length - 2];
        const previousFolder = filterFolders[filterFolders.length - 3];
        setS3Objects(data?.data?.objects);
        setCurrentFolder(folderName);
        setIsSubmitting(false);
        setPreviousFolder(previousFolder);
        setBaseFolder(false);
        setBackNav(data?.data?.objects[0]?.location);
      },
    });
  };

  const getData = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      access_key: accessKey,
      access_secret: accessSecret,
      bucket_name: bucketName,
      file_location: radioValue?.location,
      name: radioValue?.name,
      type: selectedFileType,
      separator,
      report_id,
    };
    uploadFromS3(data, {
      onError: (error, variables, context) => {
        toast.error("Error uploading data source", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
        setIsSubmitting(false);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["datasources"] });
        if (report_id) {
          queryClient.invalidateQueries({ queryKey: ["report-datasources", report_id] });
        }
        toast.success("File uploaded succesfully", {
          position: "top-center",
          toastId: customId,
          autoClose: 1000,
          theme: "colored",
        });
        onClose();
        setIsSubmitting(false);
      },
    });
  };

  const handleNavigation = async (e: any) => {
    setGoingBack(true);
    e.preventDefault();
    if (view === S3ModelInView.FOLDER) {
      if (backNav?.split("/").length === 2 || backNav === "") {
        setView(S3ModelInView.ACCESS);
      } else {
        fetchFolderObjects(e);
      }
    } else {
      setModalInView(ModelInView.SOURCE);
    }
  };

  const filterObjects: S3Objects[] =
    (s3Objects &&
      s3Objects.length > 0 &&
      s3Objects.filter((obj: S3Objects) => (obj && (obj?.name as string)?.split(".")[1] === selectedFileType) || obj?.content_type === "folder")) ||
    [];

  const emptyBucket = (filterObjects as S3Objects[]) && filterObjects.length === 0;

  const dataSources = filterObjects;

  return (
    <div>
      <div className="flex justify-between items-center px-6 pb-4 pt-6  border-b-[1px] border-b-grey-100">
        {view !== S3ModelInView.ACCESS ? (
          <Typography variant="textxl" className="font-semibold">
            {currentFolder || "Add S3 Bucket Source"}
          </Typography>
        ) : (
          <div className="flex items-center gap-2">
            <img src={amazon_s3} alt="data source" className="h-8 w-8" />
            <Typography variant="textxl" className="font-semibold">
              Add S3 Bucket Source
            </Typography>
          </div>
        )}{" "}
        <div onClick={() => onClose()} className="hover:cursor-pointer h-6 w-6">
          <IoMdClose className="w-full h-full" />
        </div>
      </div>
      {view === S3ModelInView.ACCESS && (
        <form className="flex flex-col gap-8 overflow-auto" onSubmit={getS3Access}>
          <div>
            <div className="flex flex-col gap-1 px-6 mt-4">
              <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                Access Key
              </Typography>
              <input
                className="h-[44px] mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Access Key"
                onChange={(event) => {
                  setAccessKey(event.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-1 px-6 mt-4">
              <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                Access Secret
              </Typography>

              <div className="relative rounded-md">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <img className="text-grey-500 sm:text-sm" alt="search" src={password_lock_icon} />
                </div>

                <input
                  className="h-[44px] px-3 pl-10 py-auto bg-white border shadow-sm border-grey-300 placeholder-grey-500 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Access Secret"
                  autoComplete="current-password"
                  type={showSecret ? "text" : "password"}
                  onChange={(event) => {
                    setAccessSecret(event.target.value);
                  }}
                />
                <button type="button" className="absolute right-0 top-[12px] mr-4 p-auto text-gray-500" onClick={toggleSecretVisibility}>
                  <img src={view_password_icon} className="text-grey-500 sm:text-sm" alt="view password" />
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-1 px-6 mt-4">
              <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                Bucket Name
              </Typography>
              <input
                className="h-[44px] mt-[2px] px-3 py-2 bg-white border shadow-sm border-grey-300 placeholder-grey-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Bucket Name"
                onChange={(event) => {
                  setBucketName(event.target.value);
                }}
              />
            </div>
            {selectedFileType === FileTypes.CSV && (
              <>
                <div className="flex flex-col gap-1 px-6 mt-4">
                  <Typography variant="textsm" className="text-grey-900 text-left !font-medium">
                    Select a Separator
                  </Typography>

                  <div className="flex gap-3 ">
                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200 flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.COMMA,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.COMMA);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Comma
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (,)
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.TAB,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.TAB);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Tab
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (\t)
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.SEMICOLON,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.SEMICOLON);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Semicolon
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (;)
                        </Typography>
                      </div>
                    </div>

                    <div
                      className={classNames(
                        "w-[98px] hover:bg-primary-50 rounded-md border-[1px] border-primary-200  flex items-center justify-center hover:cursor-pointer",
                        {
                          "bg-primary-50": separator === ISeparatorEnumValue.PIPE,
                        }
                      )}
                      onClick={() => {
                        setSeparator(ISeparatorEnumValue.PIPE);
                      }}
                    >
                      <div className="flex justify-center gap-2 items-center w-full p-2">
                        <Typography variant="textsm" className="text-grey-500">
                          Pipe
                        </Typography>
                        <Typography variant="textsm" className="text-grey-500">
                          (|)
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between items-center px-6 pb-4 pt-4  border-t-[1px] border-t-grey-100">
            <Button type="button" title="Go back" color="info" onClick={() => setModalInView(ModelInView.SOURCE)} />
            <Button disabled={disableButton} isSubmitting={isSubmitting} type="submit" title="Continue" onClick={getS3Access} />
          </div>{" "}
        </form>
      )}
      {view === S3ModelInView.FOLDER && (
        <form className="flex flex-col gap-8 overflow-auto">
          {emptyBucket && (
            <div className="flex py-10 justify-center items-center w-full">
              <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
                No {selectedFileType} available in this folder
              </Typography>
            </div>
          )}

          <div className="flex flex-col pb-4 gap-2 w-full">
            {!emptyBucket && dataSources.length > 0 && (
              <div className="my-4 px-6">
                {dataSources?.map((data_source: S3Objects, index) => (
                  <div
                    className="w-full flex items-center justify-between  hover:bg-grey-75 px-1 py-3 rounded-md"
                    key={`${data_source.name}${Math.random()}`}
                  >
                    <div className="flex items-center gap-3">
                      <img src={data_source.content_type === "folder" ? folder_icon : file_icon} alt="pdf" className="h-6 w-6" />
                      <Typography variant="body2" className="font-medium">
                        {data_source.name}
                      </Typography>
                    </div>
                    <div className="ml-4" key={`${data_source.name}${Math.random()}`}>
                      {data_source.content_type === "file" ? (
                        <input
                          type="radio"
                          id="radioColor"
                          name="fileType"
                          value={data_source?.name}
                          checked={radioValue?.name === data_source.name}
                          onChange={(e) => setRadioValue(data_source)}
                          className="w-6 h-6 hover:cursor-pointer"
                        />
                      ) : (
                        <div
                          className="w-6 h-6 rounded-md hover:cursor-pointer flex items-center"
                          key={`${data_source.name}${Math.random()}`}
                          onClick={() => fetchFolderObjects(data_source)}
                        >
                          <SlArrowRight />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-between items-center pt-4 border-t-[1px] border-t-grey-100 px-6">
              <Button type="button" disabled={isSubmitting} title="Go back" color="info" onClick={(e) => handleNavigation(e)} />
              <Button disabled={disableButton} isSubmitting={isSubmitting} type="submit" title="Save" onClick={(e: any) => getData(e)} />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
