import { UserContext } from "providers/UserContext";
import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useFetchMe } from "services";
import { getDetails } from "utils";

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const { user, setUserContext } = useContext(UserContext);

  const getUserToken = getDetails("token");

  const verifyToken = !user?.token && !getUserToken;

  const { data, isFetching, error } = useFetchMe();

  const account = data && data.data.account;

  useEffect(() => {
    if (account && !user.token) {
      setUserContext(account);
    }
  }, [account]);

  if (verifyToken && !account) {
    return <Navigate to="/auth" />;
  }

  if (isFetching) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return <>{children}</>;
}
