import { Button } from "components";
import Typography from "components/shared/Typography";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router";

export function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-center justify-between h-screen py-32">
        <div className="flex flex-col gap-2 justify-center items-center">
          <Typography variant="displayxl" className="text-shade-black" weight="bold">
            Oops!
          </Typography>{" "}
          <Typography variant="textmd" className="text-shade-black mb-2" weight="regular">
            we couldn't find the page you're looking for.
          </Typography>{" "}
        </div>
        <img
          src={
            "https://s3-alpha-sig.figma.com/img/9c51/4526/60462ea3373c059d9d019e4b64672802?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pYrVC6LL-N1e1pmuK-BKb~YChTZXDht1GrI1l8uUgqED64F1jg8E2Cwewc9IEJSawhwA1fiapm~33Wx8EW8K-TzPt8rx38JN4Mq5ue8eMswSwwoUVaCN6YBPKvbBkqZeG~jO16ck0MJpidD51Rzhs~l9y1uThbIBe3Ybskdq90vCEoXO-f~G2pTS9Z7pYdyB7W7ToiFWQjpUm3rd38tqY~9m9xLdpz2HIcLoIBDLB~9yCx1C7F96SCMklpQVPMfWtOYetieCn43suftTYDJ0QIh7MN-Ou0IcwfyxN48kkYkS7D7guSgxDXO0rviXru8nzDk9kmZnGH53C~DXgqx1jQ__"
          }
          alt="not found"
        />
        <Button title="Go Back" icon={<FaArrowLeftLong />} className="rounded-full" onClick={() => navigate(-1)} />
      </div>
      ;
    </>
  );
}
