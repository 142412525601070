import styled from "styled-components";

interface TypographyVariant {
  fontFamily?: string;
  size: string;
  lineHeight: string;
  letterSpacing: string;
}

type FontWeight = "regular" | "medium" | "semibold" | "bold";

interface TypographyProps {
  variant: keyof typeof typographyVariants;
  color?: string;
  weight?: FontWeight;
  children: React.ReactNode;
  className?: string;
}

const typographyVariants: Record<string, TypographyVariant> = {
  display3xl: {
    size: "96px",
    lineHeight: "150%",
    letterSpacing: "-2%",
  },
  display2xl: {
    size: "72px",
    lineHeight: "140%",
    letterSpacing: "0%",
  },
  displayxl: {
    size: "60px",
    lineHeight: "140%",
    letterSpacing: "0%",
  },
  displaylg: {
    size: "48px",
    lineHeight: "140%",
    letterSpacing: "0%",
  },
  displaymd: {
    size: "36px",
    lineHeight: "140%",
    letterSpacing: "0%",
  },
  displaysm: {
    size: "30px",
    lineHeight: "140%",
    letterSpacing: "0%",
  },
  displayxs: {
    size: "24px",
    lineHeight: "140%",
    letterSpacing: "0%",
  },
  textxl: {
    size: "20px",
    lineHeight: "150%",
    letterSpacing: "0%",
  },
  textlg: {
    size: "18px",
    lineHeight: "150%",
    letterSpacing: "0%",
  },
  textmd: {
    size: "16px",
    lineHeight: "150%",
    letterSpacing: "0%",
  },
  textsm: {
    size: "14px",
    lineHeight: "150%",
    letterSpacing: "0%",
  },
  textxs: {
    size: "12px",
    lineHeight: "150%",
    letterSpacing: "0%",
  },
};

const TypographyBase = styled.div<TypographyProps>`
  font-family: ${({ variant }) => typographyVariants[variant]?.fontFamily || "ABC Diatype Regular"};
  font-size: ${({ variant }) => typographyVariants[variant]?.size};
  line-height: ${({ variant }) => typographyVariants[variant]?.lineHeight};
  letter-spacing: ${({ variant }) => typographyVariants[variant]?.letterSpacing};
`;

export default function Typography(props: TypographyProps) {
  const { variant, children, weight = "regular", className = "text-grey-500" } = props;

  const baseStyle = `font-${weight} ${className}`;

  return (
    <TypographyBase variant={variant} className={`${baseStyle}`}>
      {children}
    </TypographyBase>
  );
}
