import Typography from "./Typography";

interface INoDataComponent {
  item: string;
}
export const NoDataComponent = (props: INoDataComponent) => {
  const { item } = props;
  return (
    <div className="flex py-10 justify-center items-center w-full border-[1px] border-grey-100">
      <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
        No {item} Available
      </Typography>
    </div>
  );
};
