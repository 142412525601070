import { useHover, useInteractions, useFloating, autoUpdate, offset } from "@floating-ui/react";
import { useState } from "react";

export interface ITooltip {
  text: string;
  children?: React.ReactNode;
}

export const Tooltip = (props: ITooltip) => {
  const { children, text } = props;

  const [open, onOpenChange] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    placement: "top",
    open,
    onOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 6,
      }),
    ],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <span ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </span>

      {open && (
        <div className="bg-grey-700 text-shade-white rounded-md p-2" ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          {text}
        </div>
      )}
    </>
  );
};
