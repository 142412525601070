import { useDeleteReports, useFetchReports } from "services";
import Header from "components/shared/Header";
import Loading from "components/shared/Loading";
import ReportCard from "components/report/ReportCard";
import EmptyReportsPage from "components/error/EmptyReportsPage";
import Typography from "components/shared/Typography";
import { Button } from "components";
import { MdDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { IReport } from "types";
import { toast } from "react-toastify";
import { queryClient } from "index";
import { Pagination } from "components/shared/Pagination";

export function Reports() {
  const headerProps = {
    title: "Reports",
    buttonTitle: "New Report",
  };

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching, error } = useFetchReports(String(currentPage));

  const reports = data?.data.reports as Partial<IReport>[];
  const total = data?.data.total as number;
  const displayReports = reports && reports.length > 0 && !isFetching && !error;
  const displayEmptyReport = reports && reports.length === 0 && !isFetching;

  const [selectedReports, setSelectedReports] = useState<number[]>([]);

  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);
    if (selectedAll) {
      setSelectedReports([]);
    } else {
      setSelectedReports(reports.map((report) => report.id as number));
    }
  };

  const { deleteReports } = useDeleteReports();

  const customId = "custom-toast-id";

  const reportNumber = selectedReports.length > 0 ? "Reports" : "Report";

  const handleDeleteSelected = () => {
    deleteReports(selectedReports, {
      onError: (error, variables, context) => {
        toast.error("Error deleteing reports", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });

        setSelectedReports([]);

        toast.success(`${reportNumber} deleted Successfully`, {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
      },
    });
  };

  const handleSelectBenefit = async (item: number) => {
    try {
      let checkedReportState = [...selectedReports];
      if (checkedReportState.includes(item)) checkedReportState = checkedReportState.filter((id) => id !== item);
      else checkedReportState = checkedReportState.concat(item);
      setSelectedReports(checkedReportState);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="flex flex-col items-center w-[700px] pb-10 h-full ">
      <div>
        {isFetching && (
          <div className="h-full w-[758px] mt-20">
            <Loading numberOfLoaders={7} className="bg-grey-200 !h-16" />
          </div>
        )}
        {error && (
          <div className="flex min-h-full w-full justify-center items-center mt-[280px] text-center">
            {" "}
            <Typography variant="displaymd" className="text-grey-600 text-center">
              There was an unexpected error while retrieving reports
            </Typography>
          </div>
        )}
        {displayEmptyReport && <EmptyReportsPage />}
        {displayReports && (
          <div>
            <Header title={headerProps.title} />

            <div className="flex flex-col gap-8">
              <div className="flex justify-between pb-5 border-b-[1px] border-b-grey-100">
                <div className="flex gap-2 items-center">
                  <input
                    type="checkbox"
                    id="radioColor"
                    className="h-5 w-5 hover:cursor-pointer"
                    checked={selectedReports.length === reports.length}
                    onChange={handleSelectAll}
                  />
                  <Typography variant="body2" className="text-shade-black">
                    Select All
                  </Typography>
                </div>
                <Button
                  icon={<MdDeleteOutline />}
                  title="Delete"
                  color="info"
                  weigth="regular"
                  disabled={selectedReports.length === 0}
                  onClick={handleDeleteSelected}
                />
              </div>
              <div className="flex flex-col gap-5">
                {reports.map((report) => (
                  <ReportCard key={report.id} report={report} selectedReports={selectedReports} onToggleSelection={handleSelectBenefit} />
                ))}
                {total && total > 10 && (
                  <div className="mt-2">
                    <Pagination onChangePage={setCurrentPage} pageSize={10} currentPage={currentPage} total={total || 0} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
