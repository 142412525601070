import { AddDataSourceModal, Button } from "components";
import DataSourceComponent from "components/Datasources/DataSourceComponent";
import Header from "components/shared/Header";
import Loading from "components/shared/Loading";
import Typography from "components/shared/Typography";
import { useFetchDataSources } from "services";
import { MdAdd } from "react-icons/md";
import { useState } from "react";
import { Modal } from "components/shared/Modal";
import { IDataSource } from "types";
import { Pagination } from "components/shared/Pagination";

export function DataSources() {
  const headerProps = {
    title: "Data Sources",
    buttonTitle: "New Source",
  };

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching, error } = useFetchDataSources(String(currentPage));

  const displayNoDataSourcesAvailable = data && data.data.data_sources.length === 0 && !isFetching;

  const dataSources = data?.data?.data_sources as Partial<IDataSource>[];

  const total = data?.data.total as number;

  const displayDataSources = dataSources && dataSources.length > 0 && !isFetching;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-col items-center w-[700px] pb-10">
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          variant="full"
          children={<AddDataSourceModal onClose={() => setModalOpen(false)} />}
        />
      )}
      <div>
        {error && (
          <div className="mt-40 w-full flex justify-center items-center">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black text-center">
              There was an unexpected error while retrieving data sources
            </Typography>
          </div>
        )}
        {isFetching && (
          <div className="h-full w-[758px] mt-20">
            <Loading numberOfLoaders={6} className="bg-grey-200 !h-16" />
          </div>
        )}
        {displayNoDataSourcesAvailable && (
          <div className="flex justify-center items-center h-full w-full">
            <div className="flex flex-col items-center gap-8">
              <Typography variant="displaylg" className="gradient-text text-center">
                No Data Sources Available
              </Typography>
              <div className="flex justify-end items-center mb-8">
                <Button icon={<MdAdd />} title={headerProps.buttonTitle} onClick={() => setModalOpen(true)} />
              </div>
            </div>
          </div>
        )}
        {displayDataSources && (
          <>
            <Header title={headerProps.title} />

            <div className="flex justify-end items-center mb-8">
              <Button icon={<MdAdd />} title={headerProps.buttonTitle} onClick={() => setModalOpen(true)} />
            </div>
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-5">
                {dataSources?.map((dataSource) => (
                  <div key={dataSource.id}>
                    <DataSourceComponent
                      name={dataSource.name as string}
                      id={dataSource.id as string}
                      updated={dataSource.updated as string}
                      type={dataSource.type as string}
                    />
                  </div>
                ))}
                {total && total > 20 && (
                  <div className="mt-2">
                    <Pagination onChangePage={setCurrentPage} pageSize={10} currentPage={currentPage} total={total || 0} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
