import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

export const AppContext = createContext({
  isPdfOpen: false,
  togglePdfModal: () => {},
  setDataSource: (value: string) => {},
  startDataSourceDownload: () => {},
  dataSourceToDownload: "",
  dataSourceToDownloadName: "",
  dataSourceDownload: false,
  setPage: (value: number) => {},
  renderedPage: 0,
  dataSourceToDownloadType: "",
  setDataSourceType: (value: string) => {},
  setDataSourceName: (value: string) => {},
  closePdf: () => {},
});

interface IAppProviderProps {
  children: React.ReactNode;
}

function AppProvider(props: IAppProviderProps) {
  const { children } = props;
  const [isPdfOpen, setIsPdfOpen] = useState<boolean>(false);
  const [dataSourceDownload, setDataSourceDownload] = useState<boolean>(false);
  const [dataSourceToDownload, setDataSourceToDownload] = useState<string>("");
  const [renderedPage, setRenderedPage] = useState<number>(1);
  const [dataSourceToDownloadType, setDataSourceToDownloadType] = useState<string>("");

  const [dataSourceToDownloadName, setDataSourceToDownloadName] = useState<string>("");

  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  const noPage = pathname.split("/")?.pop() === "";

  useEffect(() => {
    if (noPage) {
      navigate("/reports");
    }
  }, [noPage]);

  const setPage = (value: number) => {
    setRenderedPage(value);
  };

  const togglePdfModal = () => {
    setIsPdfOpen(!isPdfOpen);
  };

  const startDataSourceDownload = () => {
    setDataSourceDownload(true);
  };

  const closePdf = () => {
    setIsPdfOpen(false);
  };

  const setDataSource = (value: string) => {
    setDataSourceToDownload(value);
  };

  const setDataSourceType = (value: string) => {
    setDataSourceToDownloadType(value);
  };

  const setDataSourceName = (value: string) => {
    setDataSourceToDownloadName(value);
  };

  return (
    <AppContext.Provider
      value={{
        isPdfOpen,
        togglePdfModal,
        dataSourceToDownload,
        setDataSource,
        dataSourceDownload,
        startDataSourceDownload,
        setPage,
        renderedPage,
        dataSourceToDownloadType,
        setDataSourceType,
        closePdf,
        setDataSourceName,
        dataSourceToDownloadName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
