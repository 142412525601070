import { amazon_s3, csv_icon, docx_icon, dropbox_icon, google_drive_icon, pdf_icon, upload_icon, xlsx_icon } from "assets/svg";
import { FormikProps } from "formik";
import Fuse from "fuse.js";
import { BlockData, DataSources } from "types";
import Cookie from "js-cookie";
import React from "react";

export function formikHelper<T, key extends keyof T>(
  formik: FormikProps<T>,
  value: key,
  helpertext?: string
): { helpertext: string; status: "error" | "default" } {
  const error = formik.touched[value] && (formik.errors[value] as string);
  return {
    status: formik.touched[value] && formik.errors[value] ? "error" : "default",
    helpertext: error || helpertext || "",
  };
}

export const BACKEND = process.env["REACT_APP_BACKEND_URL"] as string;

export const convertArrayToObjectOfObjects = <T>(dataSources: Partial<T>[]) => {
  const objectOfObjects: any = {};
  dataSources.forEach((item: any, index: number) => {
    objectOfObjects[index] = item;
  });
  return objectOfObjects;
};

export const convertObjectsToObjectsOfObjectsWithUUIDs = <T extends object>(objects: T[]): { [key: string]: T & { uuid: string } } => {
  const result: { [key: string]: T & { uuid: string } } = {};
  objects.forEach((obj) => {
    const uuid = crypto.randomUUID();
    result[uuid] = {
      ...obj,
      uuid,
    };
  });
  return result;
};

export const fuzzySearch = <T>(key: string, query: string, data: Partial<T>[]): Partial<T>[] => {
  const fuse = new Fuse(data, {
    keys: [key],
    threshold: 0.3,
    location: 0,
    distance: 100,
    minMatchCharLength: 1,
    isCaseSensitive: false,
    includeScore: false,
  });

  const results = fuse.search(query);
  return results.map((result) => result.item);
};

export const validateBlockData = (blocksData: { [key: string]: Partial<BlockData> }): boolean => {
  for (const blockId in blocksData) {
    const block = blocksData[blockId];

    if (block.result_format === "table") {
      if (!block.table_row_description || !block.table_columns) {
        return true;
      }
    }

    if (block.result_format === "chart") {
      if (!block.chart_instruction || !block.chart_type) {
        return true;
      }
    }

    if (!block.result_style || !block.result_format || !block.title || !block.query || !block.data_source_id) {
      return true;
    }
  }

  return false;
};

export const createColors = (arrayLength: number) => {
  const colors = [];
  const hueStep = 360 / arrayLength;

  for (let i = 0; i < arrayLength; i++) {
    const hue = i * hueStep;
    const saturation = 80; // Adjust saturation as needed
    const lightness = 50; // Adjust lightness as needed
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    colors.push(color);
  }

  return colors;
};

export type ReportGraphDataItemType = {
  x: string[];
  y: string[];
};
export const transformData = (data: ReportGraphDataItemType) => {
  const labels = data?.x;

  const colors = createColors(data?.x?.length);

  const datasets = [
    {
      data: data?.y,
      backgroundColor: colors,
      hoverOffset: 4,
    },
  ];

  return {
    labels,
    datasets,
  };
};

export const getIcon = (type: string) => {
  return type === "csv"
    ? csv_icon
    : type === "pdf"
      ? pdf_icon
      : type === "docx"
        ? docx_icon
        : type === "local"
          ? upload_icon
          : type === "amazon_s3"
            ? amazon_s3
            : type === "google_drive"
              ? google_drive_icon
              : type === "dropbox"
                ? dropbox_icon
                : xlsx_icon;
};

export const dataSourceName = (selectedDataSource: string) => {
  switch (selectedDataSource) {
    case DataSources.AMAZON_S3:
      return "S3 Bucket";
    case DataSources.DROPBOX:
      return "Dropbox";
    case DataSources.GOOGLE_DRIVE:
      return "Google Drive";
  }
};

export const getDetails = (name: string) => {
  return Cookie.get(name);
};
export const SetDetails = (name: string, value: string) => {
  Cookie.set(name, value);
};

export const cleanString = (jsonString: string) => {
  // Parse the JSON string into a JavaScript object
  const data: { [key: string]: any[] } = JSON.parse(jsonString);

  // Convert each array element to a string and join them with a comma
  const cleanedData: { [key: string]: string[] } = Object.entries(data).reduce((acc, [key, value]) => {
    // @ts-ignore
    acc[key] = value.map((item) => item?.toString());
    return acc;
  }, {});

  const result = transformGenericData(cleanedData);

  return result;
};

type GenericData = { [key: string]: string[] };

// Convert clean data to a renderable array for the table
const transformGenericData = (data: GenericData) => {
  const keys = Object.keys(data);
  const rowCount = data[keys[0]]?.length; // Assuming all arrays are the same length

  return Array.from({ length: rowCount }, (_, i) => {
    const row: { [key: string]: string } = {};

    keys.forEach((key) => {
      row[key] = data[key][i];
    });

    return row;
  });
};

export const customId = "custom-toast-id";

export const cleanReferenceString = (text: string) => {
  let cleanedText = text
    .replace(/\\u([0-9]{4})/g, "$1") // Decode Unicode sequences
    .replace(/\\n/g, "\n") // Convert escaped newlines to actual newlines
    .replace(/---.*?---/g, "") // Remove content between "---"
    .replace(/\s+/g, " ") // Replace multiple spaces with a single space
    .trim(); // Remove leading and trailing spaces

  // Split into lines, trim each line, and filter out empty lines
  const cleanedLines = cleanedText
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line); // Remove empty lines

  return cleanedLines.join(" ");
};

export class S3FolderStack {
  private stack: string[] = [];

  push(folderPath: string) {
    this.stack.push(folderPath);
  }

  pop(): string | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return this.stack.pop();
  }

  peek(): string | null {
    if (this.isEmpty()) {
      return null;
    }
    return this.stack[this.stack.length - 1];
  }

  isEmpty(): boolean {
    return this.stack.length === 0;
  }

  listContents(): string[] {
    return [...this.stack];
  }
}
